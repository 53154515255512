import React, { Component } from "react";
import FDA_LOGO from "../../assets/logo.jpeg";

interface NavBarProps {}
interface NavBarState {
  view_user: boolean;
  loading: boolean;
}

export class NavBar extends Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);

    this.state = {
      loading: false,
      view_user: false,
    };
  }
  componentDidMount(): void {}
  render() {
    return (
      <div>
        <div
          className={`
             bg-white py-1 pl-3 fixed top-0 right-0 left-0 shadow-md z-50`}
        >
          <div
            className={`container mx-auto lg:px-20
            `}
          >
            <div className="flex flex-row items-center justify-between gap-3">
              <div className="flex flex-row items-center gap-2">
                <div>
                  <img
                    className="h-14"
                    src={FDA_LOGO}
                    alt="Valuation Management System"
                  />
                </div>

                <div className="flex flex-row items-center gap-2">
                  <div className="text-black py-4 font-extrabold text-lg">
                    Rwanda FDA
                  </div>
                </div>
              </div>

              <a
                href="https://rwandafda.gov.rw"
                title="Rwanda FDA"
                className="px-6 py-2 rounded-md border border-primary-800 hover:bg-primary-800 text-primary-800 font-bold hover:text-white w-max text-sm"
                target={"_blank"}
                rel="noreferrer"
              >
                Back to home
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
