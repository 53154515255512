import React, { Fragment } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Homepage } from "./containers/Homepage/Homepage";
// import AppLoading from "./components/AppLoading/AppLoading";
import { NavBar } from "./components/NavBar/NavBar";
import NetworkError from "./components/NetworkError/NetworkError";
import Footer from "./components/Footer/Footer";

//* Components

//* Interfaces
// props for the component
interface AppProps {}

interface AppState {
  loading: boolean;
  openSideNav: boolean;
}

export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loading: false,
      openSideNav: true,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <Router basename="/">
          <div className="h-screen">
            {/* Check connectivity */}
            {navigator.onLine === false && <NetworkError />}
            {<NavBar />}
            <div className={`pt-14 overflow-y-auto`} style={{ zIndex: 9 }}>
              <div className={`min-h-screen`}>
                <Switch>
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/login" component={Homepage} />
                  <Route exact component={Homepage} />
                </Switch>
              </div>
            </div>
            {/* Footer */}
            <Footer />
          </div>
        </Router>
      </Fragment>
    );
  }
}
