import React, { Component } from "react";
import { GiMedicines } from "react-icons/gi";
import { ImUsers } from "react-icons/im";
import { BiSupport } from "react-icons/bi";
import { RiComputerLine } from "react-icons/ri";

interface AppProps {}

interface AppState {}

export class Homepage extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div className="h- bg-gray-400">
        <div className="fixed bg-hero-pattern top-0 right-0 left-0 bottom-0 filter blur-lg"></div>
        <div className="min-h-full bg-transparent top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center pt-20">
          <div className="grid grid-cols-12 w-full container mx-auto lg:px-0">
            <div className="col-span-12 lg:col-span-1"></div>
            <div className="col-span-12 lg:col-span-10 p-4 md:p-0">
              <div className="rounded-xl bg-white shadow-md p-0 animate__animated animate__backInUp">
                <div className="font-extrabold text-2xl text-center bg-primary-700 text-white rounded-t-xl px-6 py-4 animate__animated animate__fadeIn animate__slower">
                  Welcome to Rwanda FDA Portal
                </div>
                <div className="p-3 grid grid-cols-12 gap-3">
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="border border-primary-300 rounded-xl p-4 flex flex-col items-center justify-center w-full h-full group">
                      <div>
                        <div className="h-24 w-24 rounded-full flex flex-row items-center justify-center bg-primary-700 text-white animate__animated animate__bounceIn animate__slower">
                          <GiMedicines className="text-6xl" />
                        </div>
                      </div>
                      <div className="text-3xl font-extrabold mt-2">MA-MS</div>
                      <div className="text-center">
                        Market Authorization Management System
                      </div>
                      <div className="w-full pt-5">
                        <a
                          href={"http://mams.rwandafda.gov.rw"}
                          className="flex flex-row items-center justify-center gap-2 px-3 py-2 w-full rounded-md bg-primary-100 text-primary-800 font-bold group-hover:bg-primary-700 group-hover:text-white"
                          title="Click to Open MAMS system"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Open the system</span>
                          <div></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="border border-green-500 rounded-xl p-4 flex flex-col items-center justify-center w-full h-full group">
                      <div>
                        <div className="h-24 w-24 rounded-full flex flex-row items-center justify-center bg-green-600 text-white animate__animated animate__bounceIn animate__slower">
                          <ImUsers className="text-6xl" />
                        </div>
                      </div>
                      <div className="text-3xl font-extrabold mt-2">HR-MS</div>
                      <div className="text-center">
                        Human Resource Management System
                      </div>
                      <div className="w-full pt-5">
                        <a
                          href={"https://hrms.rwandafda.gov.rw"}
                          className="flex flex-row items-center justify-center gap-2 px-3 py-2 w-full rounded-md bg-primary-100 text-primary-800 font-bold group-hover:bg-primary-700 group-hover:text-white"
                          title="Click to Open HR system"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Open the system</span>
                          <div></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="border border-yellow-500 rounded-xl p-4 flex flex-col items-center justify-center w-full h-full group">
                      <div>
                        <div className="h-24 w-24 rounded-full flex flex-row items-center justify-center bg-yellow-600 text-white animate__animated animate__bounceIn animate__slower">
                          <BiSupport className="text-6xl" />
                        </div>
                      </div>
                      <div className="text-3xl font-extrabold mt-2">
                        SUPPORT
                      </div>
                      <div className="text-center">Customer Support System</div>
                      <div className="w-full pt-5">
                        <a
                          href={"https://customersupport.rwandafda.gov.rw"}
                          className="flex flex-row items-center justify-center gap-2 px-3 py-2 w-full rounded-md bg-yellow-100 text-yellow-800 font-bold group-hover:bg-yellow-600 group-hover:text-white"
                          title="Click to Open Customer Support system"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Open the system</span>
                          <div></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="border border-yellow-500 rounded-xl p-4 flex flex-col items-center justify-center w-full h-full group">
                      <div>
                        <div className="h-24 w-24 rounded-full flex flex-row items-center justify-center bg-yellow-600 text-white animate__animated animate__bounceIn animate__slower">
                          <RiComputerLine className="text-6xl" />
                        </div>
                      </div>
                      <div className="text-3xl font-extrabold mt-2">
                        IRIMS CLIENT
                      </div>
                      <div className="text-center">
                        Integrated Regulatory Information Management System.
                        Client Window
                      </div>
                      <div className="w-full pt-5">
                        <a
                          href={"https://www.irims.rwandafda.gov.rw/portal/"}
                          className="flex flex-row items-center justify-center gap-2 px-3 py-2 w-full rounded-md bg-yellow-100 text-yellow-800 font-bold group-hover:bg-yellow-600 group-hover:text-white"
                          target="_blank"
                          title="Open IRIMS CLIENT"
                          rel="noreferrer"
                        >
                          <span>Open the system</span>
                          <div></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="border border-gray-500 rounded-xl p-4 flex flex-col items-center justify-center w-full h-full group">
                      <div>
                        <div className="h-24 w-24 rounded-full flex flex-row items-center justify-center bg-gray-600 text-white animate__animated animate__bounceIn animate__slower">
                          <RiComputerLine className="text-6xl" />
                        </div>
                      </div>
                      <div className="text-3xl font-extrabold mt-2">
                        PRIMS CLIENT
                      </div>
                      <div className="text-center">
                        Product Regulatory Information Management System. Client
                        Window
                      </div>
                      <div className="w-full pt-5">
                        <a
                          href={
                            "https://www.mis.rwandafda.gov.rw/login.iface?king=7388"
                          }
                          className="flex flex-row items-center justify-center gap-2 px-3 py-2 w-full rounded-md bg-gray-100 text-gray-800 font-bold group-hover:bg-gray-600 group-hover:text-white"
                          title="Click to PRIMS CLIENT"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Open the system</span>
                          <div></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="border border-gray-500 rounded-xl p-4 flex flex-col items-center justify-center w-full h-full group">
                      <div>
                        <div className="h-24 w-24 rounded-full flex flex-row items-center justify-center bg-gray-600 text-white animate__animated animate__bounceIn animate__slower">
                          <RiComputerLine className="text-6xl" />
                        </div>
                      </div>
                      <div className="text-3xl font-extrabold mt-2">PVMIS</div>
                      <div className="text-center">
                        Pharmacovigilance Monitoring System
                      </div>
                      <div className="w-full pt-5">
                        <a
                          href={
                            "https://pvims.rwandafda.gov.rw/security/landing"
                          }
                          className="flex flex-row items-center justify-center gap-2 px-3 py-2 w-full rounded-md bg-gray-100 text-gray-800 font-bold group-hover:bg-gray-600 group-hover:text-white"
                          title="Click to Open PVMIS"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Open the system</span>
                          <div></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4">
                    <div className="border border-gray-500 rounded-xl p-4 flex flex-col items-center justify-center w-full h-full group">
                      <div>
                        <div className="h-24 w-24 rounded-full flex flex-row items-center justify-center bg-gray-600 text-white animate__animated animate__bounceIn animate__slower">
                          <RiComputerLine className="text-6xl" />
                        </div>
                      </div>
                      <div className="text-3xl font-extrabold mt-2">
                        E-LEARNING
                      </div>
                      <div className="text-center">
                        Rwanda FDA e-Learning Platform
                      </div>
                      <div className="w-full pt-5">
                        <a
                          href={
                            "https://elearning.rwandafda.gov.rw:8080/elearning/"
                          }
                          className="flex flex-row items-center justify-center gap-2 px-3 py-2 w-full rounded-md bg-gray-100 text-gray-800 font-bold group-hover:bg-gray-600 group-hover:text-white"
                          title="Click to Open Rwanda FDA e-Learning Platform"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Open the system</span>
                          <div></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
